import * as types from "../actions/types";
import _ from "underscore";

const initialState = {
  error: null,
  loading: false,
  details: {},
  tickets: [],
  reviews:{},
  competitions: [],
  heroCompetitions: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.LUCKY_DIP_START:
      return {
        ...state,
        error: null,
      };
    case types.TICKETS_REQUEST_START:
    case types.COMPETITION_REQUEST_START:
    case types.REVIEW_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case types.LUCKY_DIP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case types.LUCKY_DIP_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.GET_SINGLE_COMPETITION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        details: payload,
      };
    case types.GET_SINGLE_COMPETITION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        details: {},
      };

    case types.GET_COMPETITION_TICKETS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        tickets: payload,
      };
    case types.GET_COMPETITION_TICKETS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        competitions: _.groupBy(payload, "group_title"),
      };
    case types.GET_COMPETITIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_HEROPRIZE_COMPETITIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        heroCompetitions: payload,
      };
    case types.GET_HEROPRIZE_COMPETITIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        reviews: payload,
        };
    case types.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        error: action.error,
        loading: false,
        };

    default:
      return state;
  }
}
